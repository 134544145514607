/* Most browsers agree on the default size of 16px. Since our Design System wants to use 20px
as the base font size, we needed to:

1) set the html font size here as 125%, which is calculated based on the typical browser default of 16px
2) tell the MUI Theme that the font size on the html element is 20px

Read more about this at: https://mui.com/material-ui/customization/typography/#html-font-size */

html {
  font-size: 125%; /* 125% of 16px = 20px, where 16 is the typical browser default */
}
